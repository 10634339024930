<template>
  <module-container route-name="modules" show-toolbar :title="$t('modules')">
    <template #route>
      <router-view />
    </template>
  </module-container>
</template>

<script lang="ts">
import { Component, Mixins } from "vue-property-decorator";
import PaginateProviderMixin from "@/mixins/PaginateProviderMixin";

@Component
export default class ModulesIndex extends Mixins(PaginateProviderMixin) {}
</script>
